/*.fg-field-properties .jsonify {
	margin-left: 20px;
	margin-right: 20px;
}*/

.fg-field-properties {
  position: relative;
  margin: 15px 0;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  /*background-color: #fff;*/
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  /*border: 1px solid #ddd;*/
  -webkit-touch-callout: initial;
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: text;
  -ms-user-select: initial;
  user-select: initial;
  z-index: 20; /* above .fg-field-overlay */
}

.fg-field-overlay :hover {
  cursor: move;
}

.fg-field-icon {
  margin-left: auto;
  width: 35%;
}

.fg-field-icon :hover {
  cursor: pointer;
}

.fg-field-icon button {
  width: 100%;
  padding: 0px;
}

.table-field-options td,
.table-field-options th {
  vertical-align: middle;
  padding: 5px;
}

.table-field-options td {
  border: 0;
  line-height: 30px;
}

.table-field-options input[type='checkbox'],
.table-field-options input[type='radio'] {
  margin-top: 0;
}

.table-field-options th.table-field-options-padding {
  width: 100%;
}

/*.fg-field-properties .control-label {*/
/*width: 110px;*/
/*}*/

.fg-field-properties .fg-tabs {
  margin-bottom: 10px;
}

/*.fg-field-properties .fg-tabs-pane {*/
/*margin-top: 10px;*/
/*}*/

/*.fg-field-properties .fg-tabs .tab-content {
	padding-top: 20px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
	border: 1px solid #ddd;
	border-top: none;
	border-radius: 0px 0px 4px 4px;
}*/

/*.fg-field-properties .fg-tabs .nav-tabs a {
	color: #999;
	font-weight: 300;
}

.fg-field-properties .fg-tabs .nav-tabs a:hover {
	background-color: inherit;
	color: #333;
}

.fg-field-properties .fg-tabs .nav-tabs .active a {
	color: #333;
	font-weight: normal;
	border-bottom: 1px solid #fff !important;
}
*/
@media (min-width: 480px) and (max-width: 979px) {
  .table-field-options input[type='text'] {
    width: 130px;
  }
}

/*@media (max-width: 767px) { 
	.table-field-options input[type="text"] {
		width: 90px;
	}	
}*/

@media (max-width: 480px) {
  .table-field-options input[type='text'] {
    width: 90px;
  }

  .fg-field-properties {
    padding: 10px;
  }

  /*.fg-field-properties form {*/
  /*margin: 0;*/
  /*}*/
}

.k-textbox,
.k-dropdown {
  border: 1px solid rgb(230, 230, 230) !important;
}

.instruction-field-editor-ribbon table.k-editor {
  height: 0 !important;
}

.custom-email-editor {
  margin-top: 25px;
  position: relative;
}

.custom-email-editor .email-editor-overlay {
  width: 100%;
  background-color: black;
  opacity: 0.1;
  position: absolute;
  z-index: 1;
}

.custom-email-editor table.k-editor .k-editor-toolbar-wrap {
  padding-right: 10px;
}

.custom-email-editor
  table.k-editor
  .k-editor-toolbar
  .k-tool-group:last-of-type {
  margin: 6px 0;
  float: right;
}

.custom-email-editor .add-variable {
  position: relative;
}

.custom-email-editor .variable-all-group {
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  display: none;
  right: 0;
  min-width: 220px;
  overflow-y: auto;
  max-height: calc(100vh - 400px);
}

.custom-email-editor .variable-all-group .variable-group-header {
  border-top: 1px solid #eee;
  line-height: 1.5rem;
  min-height: 36px;
  cursor: pointer;
  position: relative;
}

.custom-email-editor
  .variable-all-group
  .variable-group-header.variable-group-collapsed::after {
  content: '+';
  position: absolute;
  line-height: 36px;
  top: 0;
  right: 15px;
  color: #444;
  font-size: 1.5rem;
}

.custom-email-editor
  .variable-all-group
  .variable-group-header:not(variable-group-collapsed)::after {
  content: '-';
  position: absolute;
  line-height: 36px;
  top: 0;
  right: 15px;
  color: #444;
  font-size: 1.5rem;
}

.custom-email-editor .variable-all-group .variable-group-header span {
  font-size: 14px;
  display: block;
  line-height: 16px;
  padding: 10px 16px;
}

.custom-email-editor .variable-all-group .variable-group {
  max-height: 500px;
  overflow-y: auto;
}

.custom-email-editor .variable-all-group ul.variable-dropdown {
  display: none;
  margin: 0;
}

.custom-email-editor .variable-all-group ul.variable-dropdown li {
  display: block;
  line-height: 1.5rem;
  min-height: 30px;
  cursor: pointer;
  padding-left: 1rem;
  white-space: nowrap;
}

.custom-email-editor .variable-all-group ul.variable-dropdown li:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.custom-email-editor .variable-all-group ul.variable-dropdown li span {
  font-size: 14px;
  color: #42a5f5;
  display: block;
  line-height: 14px;
  padding: 8px 16px;
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
