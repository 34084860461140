.fg-field-inner .controls > div > .radio:first-child,
.fg-field-inner .controls > div > .checkbox:first-child {
  padding-top: 5px;
}

.fg-form .radio,
.fg-form .checkbox {
  margin-top: 0px;
  margin-bottom: 5px;
}

.form-horizontal .fg-form .radio,
.form-horizontal .fg-form .checkbox {
  margin-bottom: 0px;
}

.select-label {
  top: 0 !important;
  left: initial !important;
  position: relative !important;
}

.instruction-field-wrapper {
  width: 100%;
  background-color: white;
  overflow: auto;
  margin-top: 30px;
  img {
    max-width: 100%;
  }
}

.instruction-field {
  overflow: auto;
  line-height: normal !important;
  /*white-space: pre-wrap;*/
  background: none;
  background-color: white !important;
  padding: 0 0 25px 5px;
  margin-bottom: 0px;
  margin-right: auto;
  margin-left: auto;
}

.instruction-field table {
  overflow: auto;
  display: block;
}

.instruction-field:first-child div {
  padding: 0;
  overflow: hidden !important;
}

.instruction-field ul {
  box-sizing: border-box;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  margin-top: -1px;
  padding: 5px 0.4em 0;
  word-wrap: break-word;
  -webkit-nbsp-mode: space;
  -webkit-line-break: after-white-space;
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 40px;
  padding-left: 2.5em;
}

.instruction-field h1 {
  display: block;
  font-size: 24px;
  -webkit-margin-before: 16.08px !important;
  -webkit-margin-after: 16.08px !important;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  font-weight: bold;
  line-height: 42.9px;
}

.instruction-field h2 {
  display: block;
  font-size: 18px;
  -webkit-margin-before: 14.94px !important;
  -webkit-margin-after: 14.94px !important;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  font-weight: bold;
  line-height: 35.75px;
}

.instruction-field h3 {
  display: block;
  font-size: 14.04px;
  -webkit-margin-before: 14.04px !important;
  -webkit-margin-after: 14.04px !important;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  font-weight: bold;
  line-height: 28.6px;
}

.instruction-field h4 {
  display: block;
  font-size: 12px !important;
  -webkit-margin-before: 15.96px !important;
  -webkit-margin-after: 15.96px !important;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  font-weight: bold;
  text-align: left !important;
  line-height: 21.45px;
}

.instruction-field h5 {
  display: block;
  font-size: 9.96px !important;
  -webkit-margin-before: 16.6332px !important;
  -webkit-margin-after: 16.6332px !important;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  font-weight: bold;
  text-align: left !important;
  line-height: 16.6738px;
}

.instruction-field h6 {
  display: block;
  font-size: 8.04px;
  -webkit-margin-before: 18.7332px !important;
  -webkit-margin-after: 18.7332px !important;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  font-weight: bold;
  line-height: 14.3px;
}

.instruction-field ul li {
  list-style-type: disc !important;
}

.instruction-field ul {
  list-style-type: disc !important;
  padding-left: 40px !important;
}

.instruction-field ol li {
  list-style-type: decimal !important;
}

.instruction-field ol {
  list-style-type: decimal !important;
}

.k-content {
  font-family: 'Open Sans', sans-serif !important;
}

.instruction-field strong {
  font-weight: bold;
}

.k-i-insert-video:before {
  content: '\f03d';
  font-family: FontAwesome !important;
  left: -5px;
  position: absolute !important;
  top: 0 !important;
}
.k-i-paint > .k-selected-color {
  background-color: white !important;
}

.k-i-foreground-color > .k-selected-color {
  background-color: #35384d !important;
}

.radio-list p {
  margin: 0;
}
.text-area {
  border: 1px solid rgb(239, 239, 239);
  border-radius: 3px;
  background-color: transparent;
  outline: 0;
  height: 3rem;
  width: 100%;
  font-size: 1rem;
  margin: 0 0 15px;
  padding: 3px 3px 1.6rem 6px;
  box-shadow: none;
  box-sizing: content-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

input[type='date']:focus:not([readonly]),
input[type='datetime-local']:focus:not([readonly]),
input[type='email']:focus:not([readonly]),
input[type='number']:focus:not([readonly]),
input[type='password']:focus:not([readonly]),
input[type='search']:focus:not([readonly]),
input[type='tel']:focus:not([readonly]),
input[type='text']:focus:not([readonly]),
input[type='time']:focus:not([readonly]),
input[type='url']:focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #bbb;
  box-shadow: 0 1px 0 0 #bbb;
}

input[type='date']:focus:not([readonly]) + label,
input[type='datetime-local']:focus:not([readonly]) + label,
input[type='email']:focus:not([readonly]) + label,
input[type='number']:focus:not([readonly]) + label,
input[type='password']:focus:not([readonly]) + label,
input[type='search']:focus:not([readonly]) + label,
input[type='tel']:focus:not([readonly]) + label,
input[type='text']:focus:not([readonly]) + label,
input[type='time']:focus:not([readonly]) + label,
input[type='url']:focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #bbb;
}

.datePicker input.ng-touched.ng-invalid {
  border: none !important;
  box-shadow: none !important;
}

.datetimePicker input.ng-touched.ng-invalid {
  border: none !important;
  box-shadow: none !important;
}

.datetimePicker input {
  background-color: #fafafa !important;
  color: #a9a9a9 !important;
}

.instruction-field .k-editor-toolbar .k-tool-icon.k-icon {
  color: black;
}

.instruction-field .table.k-editor {
  border-color: white;
}

.instruction-field div.isMobile p img {
  max-width: 100%;
  max-height: 100%;
}

.k-header .k-window-actions .k-link,
.k-icon {
  color: black !important;
}

.fg-field-instruction table.k-editor {
  margin-right: auto !important;
  margin-left: auto !important;
}

.cursor-pointer {
  cursor: pointer;
}
