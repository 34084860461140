.field-container {
  .fa-triangle-exclamation {
    color: orange;
  }
}

.field-settings {
  padding: 20px 20px 0 20px;
}

.hides-unless-visible {
  display: none;
}

.hides-unless-visible.visible {
  display: block;
}

.field-dragging-title {
  font-weight: 600;
  font-size: 16px;
}

.field-dragging-title.visible {
  display: inline-block;
}

.dragging-container {
  flex-grow: 1;
}
/* 
    If field type is currency, we display 3 columns instead of 2
*/
.fg-field-currency .field_prop {
  width: 40%;
}

.fg-field-currency .currency_prop {
  display: inline-block;
  vertical-align: top;
}

.field_prop {
  display: inline-block;
  width: 49%;
  vertical-align: top;
}

.field_prop input[type='text'],
.field_prop .table,
.field_prop .field-options-add {
  width: 95% !important;
}

.field-settings-general {
  display: flex;
  flex-direction: row;
}

.info-icon-container {
  font-size: 8px;
}

.info-icon-inner-container {
  color: #a3adba;
}

.fa-info {
  color: black;
}

.hidden-by-default-indicator {
  background-color: #d9edf7;
  color: #039be5;
  border-radius: 25px;
  margin-left: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 10px;
}

.icon-btn-disabled button {
  box-shadow: none;
  color: #fafafa !important;
  cursor: not-allowed !important;
}
