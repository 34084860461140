.fg-field-required .control-label:after {
  content: ' *';
  color: #888;
}

.fg-legend-extra {
  color: #999;
  font-weight: lighter;
  display: inline-block;
}

fieldset {
  border: none;
}

/*for labels make the transform (y move) happen immediately on rendering*/
.fg-edit-canvas .fg-field.fg-edit-canvas-field .input-field label.active {
  transition: 0s;
}

/* style for invalid inputs that have been touched */

.fg-validation-summary {
  margin-top: -5px;
}
.fg-validation-summary li {
  color: red !important;
}

.tabs {
  background-color: transparent !important;
}

.tab-properties {
  text-align: center !important;
}

.tab-properties a {
  padding-left: 10px !important;
}
.table-no-border td {
  border: none !important;
}

.input-field input.ng-invalid.ng-dirty,
.input-field textarea.ng-invalid.ng-dirty {
  border-bottom: 2px solid red;
  box-shadow: none;
}
/* style for invalid inputs on a submitted form */
form.ng-submitted .input-field input.ng-invalid,
form.ng-submitted .input-field textarea.ng-invalid {
  border-bottom: 2px solid red;
  box-shadow: none;
}

.k-numeric-wrap {
  margin-bottom: 10px;
}
.has-error {
  color: red !important;
}
.formula-config-left input[type='text'] {
  width: 100% !important;
}

.formula-config-right {
  position: relative;
  top: -55px;
  border-left: 2px solid #9e9e9e;
}

.formul-builder-element {
  border-bottom: 1px solid #9e9e9e;
  line-height: 35px;
}

.btn {
  border-radius: 0.5rem;
  box-shadow: none;
}

.btn:hover {
  box-shadow: none;
}
