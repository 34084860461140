.fg-tabs > .nav-tabs {
  margin-bottom: 0px;
}

.fg-tabs .tab-content {
  padding: 10px;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
}

.fg-tabs a.active {
  border-bottom: 2px solid #428bca;
}

.fg-tabs-pane {
  clear: both;
}
.form-group:after {
  content: '.';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
.fg-edit-canvas fieldset {
  border: none;
}
