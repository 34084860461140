.field-container {
  position: relative;
}

.fg-edit-canvas .fg-field,
.fg-edit-palette .fg-field {
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  position: relative;
}

.fg-edit-canvas .fg-field,
.fg-edit-palette .fg-field:hover {
  background-color: #fdfdfd;
}

.fg-field-type {
  position: absolute;
  top: -10px;
  background-color: #fefefe;
  margin-left: 15px;
  padding: 0;
  z-index: 2;
}

.fg-field-attach-document {
  margin-left: 20px;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

.attach-document-confim-delete {
  padding-top: 50px;
}
.attach-document-confim-delete .ngdialog-content {
  background: #fff;
  color: #444;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1.1em;
  line-height: 1.5em;
  margin: 0 auto;
  max-width: 100%;
  padding: 1em;
  position: relative;
  width: 450px;
}

.attach-document-confim-delete .ngdialog-content .card .content {
  padding: 10px 15px 35px;
}

.ngdialog-content button {
  font-size: 13px;
}
