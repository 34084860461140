#tableBuilder {
  min-height: 440px;
}

.fg-signature-pad {
  position: relative;
  width: 500px;
  max-width: 90%;
  color: #35384d !important;
}
.fg-signature-pad .canvas-container {
  position: relative;
}
.fg-signature-pad .clear-button {
  position: absolute;
  top: 0;
  right: -5px;
  font-size: 15px;
  z-index: 100;
  cursor: pointer;
  padding: 5px 10px;
  color: #888;
}
.fg-signature-pad .instruction {
  text-align: center;
  border: 1px solid #ddd;
  margin-top: -5px;
  margin-right: -2px;
  padding: 5px;
  border-top: none;
}
.fg-signature-pad .label {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
  display: inline-block;
}
.signature-pad {
  background-color: rgb(249, 249, 249);
  border: 1px solid rgb(221, 221, 221);
  width: 100%;
}

.fg-radio-button-list p:last-child {
  padding-bottom: 20px !important;
}
.fg-checkbox {
  margin-top: 10px;
}

.fg-checkbox-label {
  left: 0;
  margin-top: -20px;
}

.input-field label.fg-checkbox-label {
  top: 20px;
}
.fg-checkbox-tick {
  margin-top: 10px;
}

.form .control-label,
.fg-edit-palette .control-label {
  float: none;
  width: 100%;
  text-align: left;
  padding: 0;
}

.form .fg-field-inner > div,
.form .fg-property-field > div,
.fg-edit-palette .fg-field-inner > div {
  margin-left: 0;
  width: 100%;
  float: none;
  padding: 0;
}

.form textarea,
.fg-edit-canvas textarea {
  white-space: pre-wrap;
}

.p-0 {
  padding: 0 !important;
}

.fileUpload {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.fileUpload span {
  font-size: 13px;
}
/* prevent this field from being clicked -(but allow in the runner) */
.fg-edit-canvas-area .not-clickable {
  pointer-events: none;
}

.file-upload-field {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 42px;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
}

.file-upload-field-left-panel {
  max-width: 70%;
  display: inline-block;
  word-break: break-word;
}

.file-upload-field-right-panel {
  max-width: 30%;
  display: inline-block;
}

.file-upload-field.dragging {
  border: 3px dashed #17a2e6;
}
.file-upload-field label {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #ddd !important;
  color: #333;
  font-size: 14px;
  height: 100%;
  line-height: 40px;
  box-shadow: none;
  border-radius: 0rem 0.5rem 0.5rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.file-upload-field .not-clickable {
  pointer-events: none;
}

.file-upload-field input {
  display: none;
}
.file-upload-field btn {
  width: 150px;
  height: 40px;
  line-height: 40px;
}
/*.file-upload-field.file-list-invalid {
      border: 1px solid pink;
}
.file-upload-field.file-list-invalid  label {
                background-color: pink!important;
            }*/
.clickable {
  pointer-events: auto;
}
.file-list {
  margin: 5px 0 0 5px;
}

.file-list .error-text {
  position: absolute;
  bottom: -21px;
  left: 0;
  color: red;
}

.file-list li {
  position: relative;
  float: left;
  margin-bottom: 5px;
  padding: 5px 10px;
  background: #efefef;
  min-width: 150px;
  color: #00f;
  font-weight: 600;
}

.file-list li span {
  color: #999;
  margin-left: 3px;
  margin-right: 15px;
  font-weight: 400;
}

.file-list li a {
  color: #2196f3;
  max-width: 95%;
}

.file-list .fa-xmark {
  color: #2196f3;
}

.file-list .fa-xmark:hover {
  cursor: pointer;
}

.file-list li * {
  float: left;
}

.file-list i {
  float: none;
  line-height: 20px;
  position: absolute;
  right: 10px;
}

label.display-name {
  position: relative;
  left: 0.65rem;
  font-size: 1rem;
  cursor: text;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}

.no-border {
  border: none;
}
.k-datepicker .k-input,
.k-timepicker .k-input {
  background: white !important;
}
.datetimePicker input {
  background: white !important;
}

.add-variable {
  position: relative;
}
