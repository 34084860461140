fg-image-selector .image-selection-info {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}

fg-image-selector .image-selection-no-images {
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  background: aliceblue;
  border: 1px solid aliceblue;
}

fg-image-selector .image-selection-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
  min-height: 150px;
}

fg-image-selector .image-selection-modifier {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20px;
}

fg-image-selector .image-selection-modifier:hover {
  background: #ddd;
}

fg-image-selector .image-selection-wrapper {
  flex: 1 1 auto;
}

fg-image-selector .image-selection-wrapper:only-child {
  margin: 0 auto;
}

fg-image-selector .image-selection {
  display: flex;
}

fg-image-selector .image-selection img {
  border: 1px solid #42a5f5;
}

fg-image-selector .image-selection .image-selection-caption {
  display: flex;
  justify-content: center;
  font-weight: 600;
}
