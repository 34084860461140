.fg-edit-canvas .fg-field-properties .control-group {
  margin-bottom: 10px;
}

.fg-edit-canvas .fg-field.fg-edit-canvas-field {
  padding-top: 15px;
  padding-bottom: 15px;
  overflow: hidden;
}

.fg-edit-canvas {
  padding-bottom: 25px;
}

.dummy-btn-container {
  position: absolute;
  bottom: 0;
  left: 25px;
}

.fg-edit-canvas-area-empty {
  display: table;
  border: 3px dashed #ddd;
  border-radius: 15px;
  background-color: #fff;
  color: #ddd;
  width: 100%;
}

.fg-edit-canvas-area-empty p {
  text-align: center;
}

.fg-edit-canvas-area-empty .icon-center {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.fg-edit-canvas-area-empty .icon-center div {
  width: 100%;
}

.fg-edit-canvas-area-empty span {
  display: block;
  font-size: 16px;
}

.fg-edit-canvas-area-empty i {
  font-size: 76px;
}

.fg-edit-canvas-area-empty > i {
  position: relative;
  top: calc(50% - 57px); /* 50% - 3/4 of icon height */
}

.fg-drag-placeholder {
  margin-bottom: 0;
  opacity: 0;
  filter: aplha(opacity(0));
  transition: height 0ms linear, opacity 0ms;
  height: 0px;
  display: none;
}

.fg-edit-canvas-dragging .fg-drag-placeholder {
  display: block;
  transition: height 100ms linear, opacity 500ms, margin-bottom 100ms;
}

.fg-drag-placeholder-visible {
  margin-bottom: 20px;
  border-radius: 4px;
  border: dashed 1px #3a87ad;
  background-color: #d9edf7;
  height: 74px;
  opacity: 1;
  filter: aplha(opacity(100));
}

.fg-field-overlay {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.fg-field-overlay-drag-top,
.fg-field-overlay-drag-bottom {
  height: 50%;
}

.fg-field-properties .fg-field-not-in-cache {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 0;
}

.fg-edit-canvas .fg-field.dragging {
  display: none;
}

@media (max-width: 768px) {
  .fg-edit-canvas-area {
    min-height: inherit;
    padding: 0;
    border: none;
  }

  .fg-edit-canvas-area-empty {
    position: static;
    margin-bottom: 20px;
  }
}

.action-buttons button,
.action-buttons i {
  float: right;
  cursor: pointer;
}

.action-buttons button:hover,
.action-buttons i:hover {
  cursor: pointer;
}
.drag-field-origin {
  line-height: 67px;
  text-align: center;
  overflow: hidden;
  height: 70px;
  background-color: #eee;
  border-radius: 4px;
  border: 2px dashed #a0a0a0;
  font-size: 15px;
}
