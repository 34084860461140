.fg-edit-canvas-area {
  height: 0px;
  padding: 10px 0px;
  line-height: 48px;
  text-align: center;
  background-color: transparent;
  color: transparent;
  overflow: hidden;
  font-size: 15px;
  font-weight: 600;
}

.fg-edit-canvas-area.dragging {
  padding: 10px 0px;
  background-color: #e6efff;
}

.over .fg-edit-canvas-area {
  height: 54px;
  padding: 0px;
  color: #0b237f;
  border-radius: 4px;
  border: 2px dashed #2b63ff;
}
